import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import HomeNav from './pages/HomeNav';
import OurStory from './pages/OurStory';
import OurBeers from './pages/OurBeers';
import Gallery from './pages/Gallery';
import Faqs from './pages/Faqs';
import FindUs from './pages/FindUs';
import ErrorPage from './pages/ErrorPage';
import ScrollToTop from './components/ScrollToTop';
import BuyNowBtn from './components/BuyNowBtn';


export default function App() {
	const location = useLocation();
	useEffect(() => {
		setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);
	}, [location])
	
	return (
		<AnimatePresence>
			<Routes location={location} key={location.key}>
				<Route path='/' element={<HomeNav />} />
				<Route path='our-story' element={<OurStory />} />
				<Route path='our-beers' element={<OurBeers />} />
				<Route path='gallery' element={<Gallery />} />
				<Route path='faqs' element={<Faqs />} />
				<Route path='find-us' element={<FindUs />} />
				<Route path='/*' element={<ErrorPage />} />
			</Routes>

			<ScrollToTop />
			<BuyNowBtn />
		</AnimatePresence>
	);
}