import gallery1 from '../img/gallery/gallery1.jpg';
import gallery2 from '../img/gallery/gallery2.jpg';
import gallery3 from '../img/gallery/gallery3.jpg';
import gallery4 from '../img/gallery/gallery4.jpg';
import gallery5 from '../img/gallery/gallery5.jpg';
import gallery6 from '../img/gallery/gallery6.jpg';
import gallery7 from '../img/gallery/gallery7.jpg';
import gallery8 from '../img/gallery/gallery8.jpg';


export default [
   {
      src: gallery1,
      alt: 'Waitress with beers'
   },
   {
      src: gallery2,
      alt: 'Kegs of beer'
   },
   {
      src: gallery3,
      alt: 'Beer glass'
   },
   {
      src: gallery4,
      alt: 'Beer pub tables room'
   },
   {
      src: gallery5,
      alt: 'Beers'
   },
   {
      src: gallery6,
      alt: 'Girl drunk beer'
   },
   {
      src: gallery7,
      alt: 'Hop of beer'
   },
   {
      src: gallery8,
      alt: 'Glass of craft beer'
   }
];