import './Review.css';

function Review({ img, fullname, stars, review }) {
   return (
      <article className="review bg-light rounded">
         <img src={img} alt={fullname+' Review'} />
         <h2>{fullname}</h2>

         <span className={'rating-'+stars}></span>

         <p className="txt-left">{review}</p>
      </article>
   );
}

export default Review;