import flavor from '../img/menus/flavor.jpg';
import aroma from '../img/menus/aroma.jpg';
import unfiltered from '../img/menus/unfiltered.jpg';
import paleale from '../img/menus/paleale.jpg';
import ipa from '../img/menus/ipa.jpg';
import kegs1 from '../img/menus/kegs1.jpg';
import kegs2 from '../img/menus/kegs2.jpg';
import kegs3 from '../img/menus/kegs3.jpg';


export default [
   {
      name: 'Craft Beers',
      items: [
         {
            name: 'Sled Flavor',
            formula: 'roma paired with 100% natural fruit flavor, amber, bitter with fruity aromas - vol 3.8 % -1/2 liter',
            img: flavor,
            price: 3.5
         },         
         {
            name: 'Sled Aroma',
            formula: 'athis beer is brewed with the freshest local hops, amber, bitter with fruity aromas - vol 5.8 % -1/2 liter',
            img: aroma,
            price: 6
         },
         {
            name: 'Unfiltered blonde',
            formula: 'craft beer - vol 4.5 % -1/4 liter',
            img: unfiltered,
            price: 3.5
         },
         {
            name: 'Pale ale',
            formula: 'craft beer with strong taste and fruit aromas - vol 7.5 % -1/4 liter',
            img: paleale,
            price: 4
         },
         {
            name: 'Strong IPA',
            formula: 'amber, bitter with fruity aromas - vol 5.5 % -1/2 liter',
            img: ipa,
            price: 5.5
         }         
      ]
   },
   {
      name: 'Kegs',
      items: [
         {
            name: 'Sled Flavor',
            formula: `aroma paired with 100% natural fruit flavor, amber, bitter with fruity aromas - lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor - vol 3.8 %`,
            img: kegs1,
            price: 40
         },
         {
            name: 'Sled Aroma',
            formula: 'this beer is brewed with the freshest local hops, amber, bitter with fruity aromas - lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor - vol 5.8 %',
            img: kegs2,
            price: 70
         },
         {
            name: 'Unfiltered blonde',
            formula: 'craft beer - lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor - vol 4.5 %',
            img: kegs3,
            price: 55
         }
      ]
   }
];