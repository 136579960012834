import './HomeNav.css';
import { motion } from 'framer-motion';
import { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import useVisible from '../hooks/useVisible';
import logo from '../img/logo.svg';

function HomeNav() {
   const mainNav = useRef();

   const handleMouseMove = e => {
      const container = mainNav.current,
            scrollSpeed = .8;

      if (container) {
         const mouseX = e.clientX,
               containerRect = container.getBoundingClientRect(),
               containerCenterX = containerRect.left + containerRect.width / 2,
               distanceFromCenter = mouseX - containerCenterX;
         container.scrollLeft += distanceFromCenter * scrollSpeed;
      }
   };
   
   const visible = useVisible(15);

   return (
      <>
			<Helmet>
				<title>Sled Beer Demo &bull; React Multipurpose Template</title>
			</Helmet>

         <motion.header
            className="logo"

            initial={{ opacity: 0, y: '100%' }}
				animate={visible ? {opacity: 0, y: -25} : {opacity: 1, y: 0} }
				transition={{ duration: .3, ease: 'easeInOut' }}
         >
            <div className="top-shadow" />
            <img src={logo} alt="Sled Logo" />
         </motion.header>

         <motion.main
            className="slider"
            ref={mainNav}
            onMouseMove={handleMouseMove}

            initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .6, ease: 'easeInOut' }}
         >
            <nav id="navbar">
               <ul>
                  <li>
                     <Link to='/our-story'>
                        <section>
                           <h1>Our Story</h1>
                           <p className="badge">How it all began</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('user-tie')} />
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/our-beers'>
                        <section>
                           <h1>Our Beers</h1>
                           <p className="badge">A Flavor Twist</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('beer-mug-empty')} />
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/faqs'>
                        <section>
                           <h1>Faqs</h1>
                           <p className="badge">the answers to your doubts</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('question')} />
                           <h3>Frequently Asked Questions</h3>
                           <p>Exhaustive answers</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/gallery'>
                        <section>
                           <h1>Gallery</h1>
                           <p className="badge">See for yourself</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('image')} />
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/find-us'>
                        <section>
                           <h1>Find Us</h1>
                           <p className="badge">Where to find our beers</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('signs-post')} />
                        </footer>
                     </Link>
                  </li>
               </ul>
            </nav>
         </motion.main>
      </>
   );
}

export default HomeNav;