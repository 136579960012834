import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import wood from '../img/wood.jpg';
import logo from '../img/logo.svg';

function Footer() {
   const thisYear = new Date().getFullYear();
   return (
      <motion.footer
         style={{ background: `url(${wood})`, backgroundSize: 'contain' }}

         initial={{ opacity: 0, y: '100%' }}
         animate={{ opacity: 1, y: 0 }}
         transition={{ duration: 1, ease: 'easeInOut' }}
         exit={{ opacity: 0, y: '100%' }}
      >
         
         <img src={logo} className="mb-2" height="90" alt="Sled Logo" />

         <div className="container grid mb-2">
            <div>
               <h2 className="mb-2">Abous us</h2>
               <p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod.</p>

               <nav className="mb-2">
                  <ul className="inline">
                     <li><Link to ='/'>Home</Link></li>
                     <li><Link to='/our-story'>Our Story</Link></li>
                     <li><Link to ='/our-beers'>Our Beers</Link></li>
                     <li><Link to='/faqs'>Faqs</Link></li>
                     <li><Link to='/gallery'>Gallery</Link></li>
                     <li><Link to ='/find-us'>Find us</Link></li>
                  </ul>
               </nav>
            </div>

            <div>
               <h2 className="mb-2">Contact us</h2>
               <ul className="contact-info">
                  <li>
                     <FontAwesomeIcon icon={solid('location-dot')} />
                     <a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sledreact_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" target="_blank" rel="noopener noreferrer">
                        <address>Your Address 1, Zip - City</address>
                     </a>
                  </li>
                  <li>
                     <FontAwesomeIcon icon={solid('phone')} />
                     <a href="tel:">+39 321 123456</a>
                  </li>             
                  <li>
                     <FontAwesomeIcon icon={solid('envelope')} />
                     <a href="mailto:">youremail@address.com</a>
                  </li>
                  <li>
                     <a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sledreact_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={brands('facebook-f')} />
                     </a>
                     <a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sledreact_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={brands('instagram')} />
                     </a>
                     <a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sledreact_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={brands('x-twitter')} />
                     </a>
                  </li>
               </ul>
            </div>
         </div>

         <p>&copy; Sled {thisYear}. All Rights Reserved.</p>
         <p>Developed by <a href="https://sled.travolgi.com" target="_blank" rel="noopener noreferrer">Travolgi</a>.</p>
      </motion.footer>
   );
}

export default Footer;