import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import reviews from '../data/reviews';
import HeaderPage from '../components/HeaderPage';
import Review from '../components/Review';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';
import beers from '../img/beers.jpg';

function OurStory() {
	return (
		<>
			<Helmet>
				<title>Our Story &bull; Sled Beer Demo &bull; React Multipurpose Template</title>
			</Helmet>

			<HeaderPage
				pageName={'Our Story'}
				subtitle={'How it all began'}
				icon={<FontAwesomeIcon icon={solid('user-tie')} className="badge-icon" />}
			/>

			<motion.main
				className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				<section>
					<h2 className="txt-center">Sled Beer</h2>
					<h3 className="subtitle txt-center mb-2">Our Mission</h3>

					<div className="grid">
						<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

						<img src={beers} className="rounded" alt="Beer" />
					</div>
				</section>

				<section className="mb-2">
					<h2 className="txt-center">What people are saying</h2>
					<h3 className="subtitle txt-center mb-2">Our fame</h3>

					<div className="grid">
						{reviews.map((review, idx) => 
							<Review key={idx}
								img={review.img}
								fullname={review.name}
								stars={review.stars}
								review={review.text}
							/>
						)}
					</div>
				</section>

				<section>
					<h2 className="txt-center">Fused With Rare Ingredients</h2>
					<div className="grid">
						<article className="txt-center">
							<h3 className="subtitle">Quality Hops</h3>
							<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
						</article>

						<article className="txt-center">
							<h3 className="subtitle">Fusion Flavours</h3>
							<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
						</article>
					</div>
				</section>

				<CallToAction
               title={'Request Info'}
               text={'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'}
               toPage={'/find-us'}
               label={'Email us Now'}
            />

			</motion.main>

			<Footer />
		</>
	);
}

export default OurStory;
