import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import faqs from '../data/faqs';
import HeaderPage from '../components/HeaderPage';
import Faq from '../components/Faq';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';


export default function Faqs() {
	return (
		<>
			<Helmet>
				<title>Faqs &bull; Sled Beer Demo &bull; React Multipurpose Template</title>
			</Helmet>

			<HeaderPage
				pageName={'Faqs'}
				subtitle={'the answers to your doubts'}
				icon={<FontAwesomeIcon icon={solid('question')} className="badge-icon" />}
			/>

			<motion.main
				className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				<section className="mb-2">
					<h2 className="txt-center">Frequently Asked Questions</h2>
					<h3 className="subtitle txt-center mb-2">Exhaustive answers</h3>

					{faqs.map((faq, idx) =>
						<Faq
							key={idx}
							question={faq.question}
							answer={faq.answer}
						/>
					)}
				</section>

				<CallToAction
               title={'What are you waiting for?'}
               text={'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'}
               toPage={'/contact'}
               label={'Contact Now'}
            />
			</motion.main>

			<Footer />
		</>
	);
}
