import { Link } from 'react-router-dom';
import bgHop from '../img/bghop.jpg';

function CallToAction({ title, text, toPage, label }) {
   return (
      <section className="p-1 txt-center rounded" style={{ background: `url(${bgHop})`, backgroundSize: 'cover' }}>
         <h2>{title}</h2>
         <p className="mb-2">{text}</p>

         <Link to={toPage} className="badge">{label}</Link>
      </section>
   );
}

export default CallToAction;