import review1 from '../img/review1.jpg';
import review2 from '../img/review2.jpg';


export default [
   {
      img: review1,
      name: 'John Doe',
      stars: 4,
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea aliquam et sie commodo.'
   },
   {
      img: review2,
      name: 'Jessica Doe',
      stars: 3,
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea aliquam et sie commodo.'
   }
];