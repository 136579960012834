import './Menus.css';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import beers from '../data/beers';
import HeaderPage from '../components/HeaderPage';
import Tabs from '../components/Tabs';
import Review from '../components/Review';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';
import bottles from '../img/bottles.png';
import review3 from '../img/review3.jpg';

const MenuItems = ({ items }) => {
   return (
      items.map((item, idx) =>
         <li key={idx} className={item.img ? 'photo' : null}>
            {item.img &&
               <img src={item.img} alt={item.name} />
            }
            <h3>
               <strong>{item.name}</strong>
               <span className="badge">$ {item.price}</span>
            </h3>
            {item.formula &&
               <p className="formula">{item.formula}</p>
            }
         </li>
      )
   );
}

function OurBeers() {
   const btns = beers.map(obj => obj.name);

   const [activeCat, setActiveCat] = useState(btns[0]),
         handleSetActive = e => setActiveCat(btns[e.target.id]),
         [menuSubcat] = beers.filter(menu => menu.name === activeCat);

   return (
      <>
			<Helmet>
				<title>Our Beers &bull; Sled Beer Demo &bull; React Multipurpose Template</title>
			</Helmet>

         <HeaderPage
				pageName={'Our Beers'}
				subtitle={'A Flavor Twist'}
				icon={<FontAwesomeIcon icon={solid('beer-mug-empty')} className="badge-icon" />}
			/>

			<motion.main
            className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
         >
				<section>
               <div className="txt-center mb-2">
                  <h2>Aromatic Ales</h2>
                  <h3 className="subtitle mb-2">Have Never Tasted So Good</h3>
                  <p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>

                  <img src={bottles} alt="Beer bottles" />
               </div>

               <Tabs
                  btns={btns}
                  activeCat={activeCat}
                  handleSetActive={handleSetActive}
               >
                  <motion.ul
                     className="menu-list"
                     
                     key={activeCat}
                     initial={{ opacity: 0, x: '100%' }}
                     animate={{ opacity: 1, x: 0 }}
                     transition={{ duration: .8, ease: 'easeOut' }}
                     exit={{ opacity: 0, x: '100%' }}
                  >

                     {menuSubcat.hasOwnProperty('subcategories') ?
                        menuSubcat.subcategories.map((subcat, idx) =>
                           <React.Fragment key={idx}>
                              <li className="subcategory">
                                 <h2 className="badge">{subcat.name}</h2>
                              </li>

                              <MenuItems items={subcat.items} />
                           </React.Fragment>
                        )
                        :
                        <MenuItems items={menuSubcat.items} />
                     }
                     
                  </motion.ul>
               </Tabs>

				</section>

            <section className="txt-center mb-2">
					<div className="grid gtc-2-1">
                  <div>
                     <h2>Good beer is easy to make.</h2>
                     <h2>Great beer is an art.</h2>
                     <h3 className="subtitle mb-2">The Science of Great Beer</h3>
                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                  </div>

						<Review
							img={review3}
							fullname={'James Marvel'}
							stars={5}
							review={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna enim.'}
						/>
					</div>
				</section>
            
            <CallToAction
               title={'Want To Stock Our Beer Selections?'}
               text={'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'}
               toPage={'/find-us'}
               label={'Contact Now'}
            />

			</motion.main>

			<Footer />
      </>
   );
}

export default OurBeers;