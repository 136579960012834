import './FindUs.css';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import HeaderPage from '../components/HeaderPage';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';

function FindUs() {
   return (
      <>
         <Helmet>
            <title>Find Us &bull; Sled Beer Demo &bull; React Multipurpose Template</title>
         </Helmet>

			<HeaderPage
				pageName={'Find us'}
				subtitle={'Where to find our beers'}
				icon={<FontAwesomeIcon icon={solid('signs-post')} className="badge-icon" />}
			/>

			<motion.main
            className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
         >
				<section className="txt-center">
					<h2>Contact us</h2>
					<h3 className="subtitle mb-2">Where we are</h3>

					<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>

               <div className="grid">
                  <article>
                     <h2>Venice</h2>
                     <h3 className="subtitle">The Beer Factory</h3>
                     <p className="mb-2">Your Address 1, Zip - City</p>

                     <iframe className="map rounded" title="Maps Venice" src="https://www.google.com/maps?q=Venice+Italy&t=&z=8&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" />
                  </article>

                  <article>
                     <h2>Parma</h2>
                     <h3 className="subtitle">The Beer Factory</h3>
                     <p className="mb-2">Your Address 1, Zip - City</p>

                     <iframe className="map rounded" title="Maps Parma" src="https://www.google.com/maps?q=Parma+Italy&t=&z=8&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" />
                  </article>
               </div>
				</section>

				<section className="bg-light txt-center rounded mb-2">
               <h2>Become a stockist</h2>
					<h3 className="subtitle">Call us Now</h3>
               <h1>
                  <FontAwesomeIcon icon={solid('phone')} />
                  <a href="tel:"> +39 321 123456</a>
               </h1>
				</section>

            <section className="grid gtc-2-1 txt-center">
               <div>
                  <h2>Get in Touch</h2>
                  <h3 className="subtitle mb-2">We will get back to you!</h3>
                  <p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br />sed do eiusmod tempor incididunt ut labore et dolore.</p>

                  <ContactForm />
               </div>

               <div>
                  <h2 className="mb-2">Our Brewery</h2>
                  <ul className="contact-info mb-2">
                     <li>
                        <FontAwesomeIcon icon={solid('location-dot')} />
                        <a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sledreact_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" target="_blank" rel="noopener noreferrer">
                           <address>Your Address 1, Zip - City</address>
                        </a>
                     </li>
                     <li>
                        <FontAwesomeIcon icon={solid('location-dot')} />
                        <a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sledreact_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" target="_blank" rel="noopener noreferrer">
                           <address>Your Address 2, Zip - City</address>
                        </a>
                     </li>
                     <li>
                        <FontAwesomeIcon icon={solid('phone')} />
                        <a href="tel:">+39 321 123456</a>
                     </li>             
                     <li>
                        <FontAwesomeIcon icon={solid('envelope')} />
                        <a href="mailto:">youremail@address.com</a>
                     </li>
                  </ul>

                  <h2 className="mb-2">Timetables</h2>
                  <div className="hours-time">
                     <time>
                        <p className="subtitle">Monday - Friday</p>
                        <h3>9:30 - 19:30</h3>
                     </time>
                     <time>
                        <p className="subtitle">Saturday</p>
                        <h3>11:00 - 14:00</h3>
                     </time>
                     <time>
                        <p className="subtitle">Sunday</p>
                        <h3>Closed</h3>
                     </time>
                  </div>
               </div>
				</section>
			</motion.main>

			<Footer />
      </>
   );
}

export default FindUs;